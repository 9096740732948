@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

/* Fuente regular */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Regular.ttf');
}

/* Fuente negritas */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Medium.ttf');
  font-weight: 600;
}

/* Fuente negritas */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Bold.ttf');
  font-weight: bold;
}

/* Fuente cursiva */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

/* Fuente negrita cursiva */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Bold-Italic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Fuente light */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/AvenirNextLTPro-Light.ttf');
  font-weight: 100;
}

h1 {
  font-family: 'Playfair Display', serif;
}

body {
  font-family: Avenir, sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

a[target] {
  color: blue;
  font-weight: bold;
}

.input-pattern-validator:invalid {
  border-color: red !important;
}