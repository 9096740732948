
.opition-carousel-item {
    min-width: 88%;
}

@media (min-width: 640px) {
    .opition-carousel-item {
        min-width: 75%;
    }
}

@media (min-width: 768px) {
    .opition-carousel-item {
        min-width: 65%;
    }
}

@media (min-width: 1280px) {
    .opition-carousel-item {
        min-width: 55%;
    }
}